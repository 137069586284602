import { createSlice } from "@reduxjs/toolkit";
import { getBrands } from "./brandsActions";

const initialState = {
  loading: false,
  error: null,
  brands: {},
  success: false,
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    resetBrandsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const { resetBrandsError } = brandsSlice.actions;
export default brandsSlice.reducer;
