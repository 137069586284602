import { useDispatch } from "react-redux";
import { addCardItem, setCard } from "../features/card/cardSlice";

const useCard = () => {
  const dispatch = useDispatch();
  function addToCard(selectedVariant, count, product) {
    dispatch(addCardItem({
      menu_item: product?.id,
      details: product,
      quantity: count,
      variant: selectedVariant,
      key: selectedVariant?.id,
      id: product?.id,
    }));
  }

  function editCard(items) {
    dispatch(setCard(items));
  }

  return {
    addToCard,
    editCard
  };
};
export default useCard;
