import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import menuItemsReducer from "../features/menuItems/menuItemsSlice";
import categoriesReducer from "../features/categories/categoriesSlice";
import brandsReducer from "../features/brands/brandsSlice";
import relatedProductsReducer from "../features/relatedProducts/relatedProductSlice";
import cardReducer from "../features/card/cardSlice";
import patientReducer from "../features/patient/patientSLice";
import promoCodesReducer from "../features/promoCodes/promoCodeSlice";
import shopDetailsReducer from "../features/shopDetails/shopDetailsSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menuItems: menuItemsReducer,
    categories: categoriesReducer,
    brands: brandsReducer,
    relatedProducts: relatedProductsReducer,
    card: cardReducer,
    patient: patientReducer,
    promoCodes: promoCodesReducer,
    shopDetails: shopDetailsReducer,
  },
});
