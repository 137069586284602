import React, { useEffect, useState, useCallback, useRef } from "react";
import "./Favorites.scss";
import { theme, Layout, Pagination, Spin, notification } from "antd";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenuItems } from "../../features/menuItems/menuItemsAction";
import { resetMenuItemsError } from "../../features/menuItems/menuItemsSlice";
import { CustomEmpty, ProductCard, ProductDrawer } from "../../components";
import Utils from "../../utils";
import classNames from "classnames";

const { Content } = Layout;

export default function Favorites() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width, isMobile } = useWindowWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    loading,
    products: { meta, results: products },
    error,
  } = useSelector((state) => state?.menuItems);
  const dispatch = useDispatch();
  const productContRef = useRef(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      page: searchParams?.get("page") ? searchParams?.get("page") : undefined,
      per_page: searchParams?.get("per_page")
        ? searchParams?.get("per_page")
        : undefined,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
  };

  useEffect(() => {
    dispatch(
      getMenuItems({
        page: searchParams?.get("page") || 1,
        per_page: searchParams?.get("per_page") || 25,
        favorite: true,
        count__gt: 0,
      })
    );
  }, [dispatch, isAuthenticated, searchParams]);

  const handlePagination = (e, a) => {
    setUrlSearchParams({
      page: e,
      per_page: a,
    });
  };

  const openDrawer = (product) => {
    setIsDrawerOpened(true);
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (location?.pathname === "/favorites") {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetMenuItemsError());
      }
    }
  }, [error, loading, location?.pathname, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content
        className="favorites-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <div
          ref={productContRef}
          style={{
            gridTemplateColumns:
              !loading && products?.length
                ? `repeat(auto-fit, minmax(${isMobile ? "168px" : "177.8px"}, ${
                    isMobile ? 350 : 250 / productContRef?.current?.offsetWidth
                  }fr))`
                : `repeat(auto-fit, minmax(${
                    isMobile ? "168px" : "177.8px"
                  },1fr))`,
          }}
          className={classNames("favorites-cont__products", {
            "favorites-cont__products__spinWrapper":
              loading || !products?.length,
          })}>
          {!products?.length && !loading ? (
            <CustomEmpty
              circleColor={token?.colorText ? token?.colorText : ""}
              boxBorderColor={token?.colorText ? token?.colorText : ""}
            />
          ) : !loading ? (
            products?.map((product) => (
              <ProductCard
                key={product?.id}
                product={product}
                navigate={() => {
                  navigate(`/product/${product?.id}`, { state: product });
                }}
                haveDrawer={true}
                openDrawer={openDrawer}
              />
            ))
          ) : (
            <Spin size="large" />
          )}
        </div>

        <Pagination
          className="favorites-cont__pagination"
          disabled={loading}
          pageSize={searchParams?.get("per_page") || 25}
          current={searchParams?.get("page") || 1}
          defaultCurrent={searchParams?.get("page") || 1}
          total={meta?.count ? meta?.count : ""}
          responsive={true}
          onChange={handlePagination}
          showQuickJumper={width < 584 && width >= 577 ? false : true}
          showSizeChanger={false}
        />
        <ProductDrawer
          isDrawerOpened={isDrawerOpened}
          close={() => {
            setIsDrawerOpened(false);
            setSelectedProduct(null);
          }}
          product={selectedProduct}
        />
      </Content>
    </>
  );
}
