import "./ConfirmModal.scss";
import { Modal, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export default function ConfirmModal({
  title,
  isOpened,
  subTitle,
  onOk,
  onCancel,
  icon,
  token,
}) {
  return (
    <Modal
      centered
      closeIcon={false}
      className="confirmModal-cont"
      onOk={() => {
        onOk();
      }}
      okText={"Yes"}
      onCancel={onCancel}
      title={
        <div className="confirmModal-cont__titleWrapper">
          {icon ? (
            icon
          ) : (
            <DeleteOutlined
              style={{
                color: `${token?.colorErrorText}`,
              }}
              className="confirmModal-cont__titleWrapper__icon"
            />
          )}
          <Typography.Text className="confirmModal-cont__titleWrapper__title">
            {title}
          </Typography.Text>
        </div>
      }
      cancelText="No"
      cancelButtonProps={{
        className: "confirmModal-cont__btn",
      }}
      okButtonProps={{
        type: "primary",
        className: "confirmModal-cont__btn",
      }}
      open={isOpened}>
      <Typography.Text className="confirmModal-cont__subTitle">
        {subTitle}
      </Typography.Text>
    </Modal>
  );
}
