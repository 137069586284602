import React, { useRef, useEffect } from "react";
import "./Register.scss";
import { Modal, Form, Typography, Input, Checkbox } from "antd";
import Utils from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthError } from "../../features/auth/authSlice";

export default function Register({ isOpened, title, onOk, onCancel, token }) {
  const formRef = useRef(null);
  const { loading, error, success } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [loading, error, isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetAuthError());
      }
    }
  }, [success, dispatch, isOpened, onCancel]);

  return (
    <Modal
      className="signUp-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText={"Register"}
      cancelButtonProps={{
        className: "signUp-cont__cancelBtn",
      }}
      okButtonProps={{
        size: "Larg",
        className: "signUp-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        formRef.current.resetFields();
        onCancel();
        if (error) {
          dispatch(resetAuthError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}>
      <Form
        className="signUp-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          onOk({ ...values, phone_number: "+1" + values?.phone_number });
        }}
        onFinishFailed={(error) => {
          console.log("error", error);
        }}>
        <div className="signUp-cont__form__formItemWrapper">
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your first name!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                First Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="First Name"
              size="large"
              onChange={() => {
                if (error && error["first_name"]) {
                  dispatch(resetAuthError("first_name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your last name!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Last Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Last Name"
              size="large"
              onChange={() => {
                if (error && error["last_name"]) {
                  dispatch(resetAuthError("last_name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="signUp-cont__form__formItemWrapper">
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: Utils?.numberValidator,
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Phone
              </Typography.Text>
            }
            colon={false}>
            <Input
              prefix="+1"
              size="large"
              onChange={() => {
                if (error && error["phone_number"]) {
                  dispatch(resetAuthError("phone_number"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="email"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: Utils?.emailValidator,
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Email
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              size="large"
              onChange={() => {
                if (error && error["email"]) {
                  dispatch(resetAuthError("email"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="signUp-cont__form__formItemWrapper">
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="address"
            rules={[
              {
                required: true,
                message: "Please input your address!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Address
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Address"
              size="large"
              onChange={() => {
                if (error && error["address"]) {
                  dispatch(resetAuthError("address"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="city"
            rules={[
              {
                required: true,
                message: "Please input your city!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                City
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="City"
              size="large"
              onChange={() => {
                if (error && error["city"]) {
                  dispatch(resetAuthError("city"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="signUp-cont__form__formItemWrapper">
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="state"
            rules={[
              {
                required: true,
                message: "Please input your state!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                State
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="State"
              size="large"
              onChange={() => {
                if (error && error["state"]) {
                  dispatch(resetAuthError("state"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Please input your zip code!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Zip Code
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Zip Code"
              size="large"
              onChange={() => {
                if (error && error["zipcode"]) {
                  dispatch(resetAuthError("zipcode"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="signUp-cont__form__formItemWrapper">
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Password
              </Typography.Text>
            }
            colon={false}>
            <Input.Password
              placeholder="Password"
              size="large"
              onChange={() => {
                if (error && error["password"]) {
                  dispatch(resetAuthError("password"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="signUp-cont__form__formItemWrapper__formItem"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
            label={
              <Typography.Text className="signUp-cont__form__formItemWrapper__formItem__text">
                Confirm Password
              </Typography.Text>
            }
            colon={false}>
            <Input.Password
              placeholder="Confirm Password"
              size="large"
              onChange={() => {
                if (error && error["confirm"]) {
                  dispatch(resetAuthError("confirm"));
                }
              }}
            />
          </Form.Item>
        </div>
        <Form.Item
          className="signUp-cont__form__formItem"
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement")),
            },
          ]}>
          <Checkbox
            onChange={() => {
              if (error && error["agreement"]) {
                dispatch(resetAuthError("agreement"));
              }
            }}>
            I have read the{" "}
            <Typography.Text
              style={{
                color: token?.Typography?.colorPrimaryText,
              }}>
              agreement
            </Typography.Text>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
