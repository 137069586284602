import React from "react";
import "./ProductFilters.scss";
import { Typography, Select, Slider, Checkbox } from "antd";

export default function ProductFilters({
  token,
  isMobile,
  isFilterOpened,
  loading,
  categories,
  catgeoriesLoading,
  selectedCategories,
  onCategoryChange,
  filterOption,
  brands,
  brandsLoading,
  selectedBrands,
  onBrandChange,
  selectedGenetics,
  onGeneticsChange,
  priceRange,
  onPriceRangeChange,
  isOnsale,
  onCheckboxChange,
  maxPrice,
}) {
  const formatter = (value) => `$${value}`;

  return isMobile && !isFilterOpened ? null : (
    <div className="productFilters-cont">
      <div className="productFilters-cont__form">
        <div className="productFilters-cont__form__formItem">
          <Typography.Text
            className="productFilters-cont__form__formItem__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Categories
          </Typography.Text>
          <Select
            placeholder="Categories"
            allowClear
            showSearch
            size="large"
            disabled={loading || catgeoriesLoading}
            loading={catgeoriesLoading}
            value={categories
              ?.filter((category) =>
                selectedCategories?.includes(category?.name)
              )
              ?.map((category) => category?.id)}
            options={categories?.map((category) => ({
              label: category?.name ? category?.name : "",
              value: category?.id ? category?.id : "",
            }))}
            onChange={onCategoryChange}
            filterOption={filterOption}
            mode="multiple"
          />
        </div>
        <div className="productFilters-cont__form__formItem">
          <Typography.Text
            className="productFilters-cont__form__formItem__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Brand
          </Typography.Text>
          <Select
            placeholder="Brand"
            allowClear
            showSearch
            size="large"
            disabled={loading || brandsLoading}
            loading={brandsLoading}
            value={selectedBrands}
            options={brands?.map((brand) => ({
              label: brand?.label ? brand?.label : "",
              value: brand?.id ? `${brand?.id}` : "",
            }))}
            onChange={onBrandChange}
            filterOption={filterOption}
            mode="multiple"
          />
        </div>
        <div className="productFilters-cont__form__formItem">
          <Typography.Text
            className="productFilters-cont__form__formItem__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Genetics
          </Typography.Text>
          <Select
            placeholder="Genetics"
            allowClear
            showSearch
            size="large"
            disabled={loading}
            value={selectedGenetics}
            options={[
              {
                label: "Indica",
                value: "indica",
              },
              {
                label: "Sativa",
                value: "sativa",
              },
              {
                label: "Hybrid",
                value: "hybrid",
              },
            ]}
            onChange={onGeneticsChange}
            filterOption={filterOption}
            mode="multiple"
          />
        </div>
        {maxPrice && priceRange?.length ? (
          <div className="productFilters-cont__form__formItem">
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Price Range
            </Typography.Text>
            <div className="productFilters-cont__form__formItem__wrapper">
              <Slider
                style={{
                  width: "calc(100% - 14px)",
                }}
                range
                disabled={loading}
                tooltip={{
                  formatter,
                }}
                min={0}
                max={maxPrice}
                onChange={onPriceRangeChange}
                value={priceRange}
              />
            </div>
          </div>
        ) : null}
        <div
          className="productFilters-cont__form__formItem"
          style={{
            width: "65px",
          }}>
          <Checkbox checked={isOnsale} onChange={onCheckboxChange}>
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Sale
            </Typography.Text>
          </Checkbox>
        </div>
      </div>
    </div>
  );
}
