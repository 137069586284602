import React, { useState } from "react";
import "./OrderDetails.scss";
import { Table, Image, Button, Card, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Counter } from "../../../../components";
import { ConfirmModal } from "../../../../modals";
import useCard from "../../../../hooks/useCard";
import logo from "../../../../assets/CanaSaleLogo.svg";
import Utils from "../../../../utils";
export default function OrderDetails({
  token,
  isMobile,
  items,
  width,
  orderLoading,
}) {
  const [selectedItem, setSelectedItem] = useState(false);
  const [isConfirmModalOpned, setIsConfirmModalOpened] = useState(false);
  const { editCard } = useCard();
  const tableColumns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      width: 56,
      render: (_, record) => {
        return (
          <Image
            src={
              record?.details?.picture_url ? record?.details?.picture_url : logo
            }
            alt="product image"
            width="40px"
            height="40px"
          />
        );
      },
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (_, record) => {
        return record?.details?.name ? record?.details?.name : "";
      },
    },
    {
      title: "Variant",
      dataIndex: "variant",
      key: "variant",
      width: width <= 1500 ? 100 : "auto",
      render: (_, record) => {
        return record?.variant?.name && record?.variant?.unit
          ? Utils?.showProductDetail(record?.variant)
          : "";
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: width <= 1500 ? 120 : "auto",
      render: (_, record) => {
        return record?.details?.sale && record?.variant?.price ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "2px",
              alignItems: "center",
            }}>
            <Typography.Text
              key={record?.varinat?.id ? record?.varinat?.id : ""}
              style={{
                color: token?.Typography?.colorPrimaryText,
              }}
              delete>
              {record?.variant?.price
                ? Utils?.roundNumber(record?.variant?.price)
                : "$"}
            </Typography.Text>
            <Typography.Text
              style={{
                color: token?.Typography?.mainColorText,
              }}
              key={record?.details?.sale?.id}>
              {record?.variant?.price
                ? Utils?.roundNumber(
                    Number(record?.variant?.price) -
                      Number(
                        (record?.variant?.price *
                          record?.details?.sale?.amount) /
                          100
                      )
                  )
                : "$"}
            </Typography.Text>
          </div>
        ) : (
          Utils?.roundNumber(record?.variant?.price)
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: width <= 1200 ? 120 : "auto",
      render: (_, record) => {
        return (
          <Counter
            count={Number(record?.quantity)}
            onCauntChnage={(val) => {
              const updatedTabledData = items?.map((el) =>
                el?.key === record?.key ? { ...record, quantity: val } : el
              );
              editCard(updatedTabledData);
            }}
            style={{
              padding: "0",
            }}
            allowDecrement={true}
            openConfirmModal={() => {
              setSelectedItem(record);
              setIsConfirmModalOpened(true);
            }}
          />
        );
      },
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      width: width <= 1500 ? 110 : "auto",
      render: (_, record) => {
        return record?.details?.sale && record?.variant?.price ? (
          <Typography.Text
            style={{
              color: token?.Typography?.colorPrimaryText,
            }}>
            {record?.variant?.price &&
            record?.details?.sale.amount &&
            record?.quantity
              ? Utils?.roundNumber(
                  record?.quantity *
                    (Number(record?.variant?.price) -
                      Number(
                        (record?.variant?.price *
                          record?.details?.sale.amount) /
                          100
                      ))
                )
              : "$"}
          </Typography.Text>
        ) : (
          <Typography.Text
            style={{
              color: token?.Typography?.colorPrimaryText,
            }}>
            {record?.variant?.price && record?.quantity
              ? Utils?.roundNumber(
                  record?.variant?.price * Number(record?.quantity)
                )
              : "$"}
          </Typography.Text>
        );
      },
    },
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      fixed: "right",
      width: 50,
      render: (_, record) => {
        return (
          <div className="orderDetails-cont__table__btnWrapper">
            <Button
              size="small"
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: "18px",
                  }}
                />
              }
              onClick={() => {
                setSelectedItem(record);
                setIsConfirmModalOpened(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="orderDetails-cont">
      {isMobile ? (
        items?.map((product) => (
          <Card
            key={product?.key}
            className="orderDetails-cont__card"
            style={{
              border: "none",
              backgroundColor: token?.Card?.colorBgCard,
            }}
            styles={{
              body: {
                width: "100%",
                display: "flex",
                gap: "12px",
                padding: "8px",
              },
            }}>
            <Image
              src={
                product?.details?.picture_url
                  ? product?.details?.picture_url
                  : logo
              }
              width="56px"
              height="56px"
            />
            <div className="orderDetails-cont__card__descriptionCont">
              <div className="orderDetails-cont__card__descriptionCont__productCont">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "4px",
                  }}>
                  <Typography.Text
                    className="orderDetails-cont__card__descriptionCont__productCont__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    {product?.details?.name ? product?.details?.name : ""}
                  </Typography.Text>
                  <Button
                    size="small"
                    icon={
                      <DeleteOutlined
                        style={{
                          fontSize: "18px",
                        }}
                      />
                    }
                    onClick={() => {
                      setSelectedItem(product);
                      setIsConfirmModalOpened(true);
                    }}
                  />
                </div>

                <Typography.Text
                  className="orderDetails-cont__card__descriptionCont__productCont__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  {product?.variant?.name && product?.variant?.unit
                    ?  Utils?.showProductDetail(product?.variant)
                    : ""}
                </Typography.Text>
                <div className="orderDetails-cont__card__descriptionCont__productCont__textWrapper">
                  {product?.details?.sale && product?.variant?.price ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}>
                      <Typography.Text
                        className="orderDetails-cont__card__descriptionCont__productCont__text"
                        key={product?.variant?.id ? product?.variant?.id : ""}
                        style={{
                          color: token?.Typography?.colorPrimaryText,
                        }}
                        delete>
                        {product?.variant?.price
                          ? Utils?.roundNumber(product?.variant?.price)
                          : "$"}
                      </Typography.Text>
                      <Typography.Text
                        style={{
                          color: token?.Typography?.mainColorText,
                        }}
                        className="orderDetails-cont__card__descriptionCont__productCont__text"
                        key={product?.details?.sale?.id}>
                        {product?.variant?.price
                          ? Utils?.roundNumber(
                              Number(product?.variant?.price) -
                                Number(
                                  (product?.variant?.price *
                                    product?.details?.sale?.amount) /
                                    100
                                )
                            )
                          : "$"}
                      </Typography.Text>
                    </div>
                  ) : (
                    <Typography.Text
                      className="orderDetails-cont__card__descriptionCont__productCont__text"
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      {product?.variant?.price
                        ? Utils?.roundNumber(product?.variant?.price)
                        : "$"}
                    </Typography.Text>
                  )}
                  <Typography.Text
                    className="orderDetails-cont__card__descriptionCont__productCont__title"
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}>
                    {product?.details?.sale &&
                    product?.variant?.price &&
                    product?.quantity
                      ? Utils?.roundNumber(
                          product?.quantity
                            ? product?.quantity *
                                (Number(product?.variant?.price) -
                                  Number(
                                    (product?.variant?.price *
                                      product?.details?.sale.amount) /
                                      100
                                  ))
                            : Number(product?.variant?.price) -
                                Number(
                                  (product?.variant?.price *
                                    product?.details?.sale.amount) /
                                    100
                                )
                        )
                      : product?.variant?.price && product?.quantity
                      ? Utils?.roundNumber(
                          product?.variant?.price * Number(product?.quantity)
                        )
                      : "$"}
                  </Typography.Text>
                </div>
              </div>
              <Counter
                count={Number(product?.quantity)}
                onCauntChnage={(val) => {
                  const updatedTabledData = items?.map((el) =>
                    el?.key === product?.key
                      ? { ...product, quantity: val }
                      : el
                  );
                  editCard(updatedTabledData);
                }}
                allowDecrement={true}
                openConfirmModal={() => {
                  setSelectedItem(product);
                  setIsConfirmModalOpened(true);
                }}
                style={{
                  padding: "0",
                }}
              />
            </div>
          </Card>
        ))
      ) : (
        <Table
          className="orderDetails-cont__table"
          columns={tableColumns}
          dataSource={items}
          loading={orderLoading}
          // bordered
          pagination={false}
          size="small"
          scroll={
            width < 1200
              ? {
                  x: "800px",
                }
              : {}
          }
        />
      )}
      <ConfirmModal
        title="Are you sure ?"
        subTitle={"You want to delete the item ."}
        isOpened={isConfirmModalOpned}
        onCancel={() => {
          setIsConfirmModalOpened(false);
        }}
        onOk={() => {
          const newTableData = items?.filter(
            (el) =>
              el?.variant?.id?.toString() !==
              selectedItem?.variant?.id?.toString()
          );
          setIsConfirmModalOpened(false);
          editCard(newTableData);
        }}
        token={token}
      />
    </div>
  );
}
