import React from "react";
import "./NotFoundPage.scss";
import { theme, Typography, Image, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import Plug from "../../assets/Plug.png";
import { useNavigate } from "react-router";

export default function NotFoundPage() {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  
  return (
    <Content
      className="notFountPage-cont"
      style={{
        backgroundColor: token?.Layout?.contentBg,
      }}>
      <div className="notFountPage-cont__content">
        <Typography.Text
          className="notFountPage-cont__content__text"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          Whoops, Page Not Found!
        </Typography.Text>
        <Typography.Text
          className="notFountPage-cont__content__title"
          style={{
            color: token?.Typography?.colorPrimaryText,
          }}>
          404
        </Typography.Text>
        <Image src={Plug} preview={false} />
        <Button
          size="large"
          type="primary"
          style={{
            width: "100%",
            maxWidth: "280px",
          }}
          onClick={() => {
            navigate("/");
          }}>
          Go Home
        </Button>
      </div>
    </Content>
  );
}
