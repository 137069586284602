import React from "react";
import "./SingleProductHeader.scss";
import { Button, Typography, Rate, Divider } from "antd";
import { ArrowLeftOutlined, HeartOutlined } from "@ant-design/icons";
import { ReactComponent as StarIcon } from "../../../../assets/startIcon.svg";
import Utils from "../../../../utils";

export default function SingleProductHeader({
  token,
  loading,
  product,
  singleProductHeaderRef,
  navigate,
}) {
  const parentCategory = Utils?.findParentCategory(product);
  return (
    <div className="singleProductHeader-cont" ref={singleProductHeaderRef}>
      <div className="singleProductHeader-cont__titleWrapper">
        <Button
          size="small"
          type="text"
          icon={
            <ArrowLeftOutlined
              style={{
                fontSize: "24px",
                color: token?.icons?.whiteIcon,
              }}
            />
          }
          onClick={() => {
            navigate();
          }}
        />
        <Typography.Text
          className="singleProductHeader-cont__titleWrapper__title"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          {product?.name && !loading ? product?.name : ""}
        </Typography.Text>
      </div>
      <div className="singleProductHeader-cont__detailWrapper">
        <Typography.Text className="singleProductHeader-cont__detailWrapper__text">
          {parentCategory && !loading ? parentCategory?.name : ""}
        </Typography.Text>
        <Divider
          type="vertical"
          className="singleProductHeader-cont__detailWrapper__divider"
          style={{
            backgroundColor: token?.Typography?.mainColorText,
          }}
        />
        <Rate
          value={product?.rate ? product?.rate : 0}
          allowHalf
          character={<StarIcon />}
          count={5}
          onChange={(rate) => {
            console.log("ProductRate", rate);
          }}
          style={{
            fontSize: "12px",
          }}
        />
        <Typography.Text className="singleProductHeader-cont__detailWrapper__text">
          {product?.rate && !loading ? product?.rate : 0}
        </Typography.Text>
        <Divider
          type="vertical"
          className="singleProductHeader-cont__detailWrapper__divider"
          style={{
            backgroundColor: token?.Typography?.mainColorText,
          }}
        />
        <Button
          type="text"
          icon={
            <HeartOutlined
              style={{
                color: token?.icons?.colorPrimaryIcon,
              }}
            />
          }
          onClick={() => {
            console.log("product heart clicked");
          }}
        />
      </div>
    </div>
  );
}
