import { createSlice } from "@reduxjs/toolkit";
import { getMe, userLogin, signUp } from "./authActions";
import LocalStorageService from "../../common/services/LocalStorageService";

// initialize userToken from local storage
const userToken = LocalStorageService?.get("authorizationData")
  ? LocalStorageService?.get("authorizationData")?.access
  : null;

const initialState = {
  loading: false,
  user: null,
  userToken: userToken ? userToken : "",
  error: null,
  success: false,
  isAuthenticated: userToken ? true : false,
  info: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    logOut: (state) => {
      state.success = false;
      state.error = null;
      state.userToken = null;
      state.user = null;
      state.loading = false;
      state.isAuthenticated = false;
      LocalStorageService.remove("authorizationData");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.userToken = action.payload.access;
        state.isAuthenticated = true;
        state.loading = false;
        state.success = true;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(getMe.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.user = action?.payload?.user;
        state.userToken = action?.payload?.access;
        state.isAuthenticated = true;
        state.loading = false;
        state.success = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.error = action?.payload;
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});
export const { resetAuthError, logOut } = authSlice.actions;
export default authSlice.reducer;
