import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getCaptcha = createAsyncThunk(
  "get_captcha",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/captcha/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);
