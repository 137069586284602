import { createSlice } from "@reduxjs/toolkit";
import LocalStorageService from "../../common/services/LocalStorageService";

const initialState = {
  loading: false,
  error: null,
  items: LocalStorageService.get("items") || [],
  success: false,
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    addCardItem: (state, action) => {
      const newItems = [...state.items, action?.payload].reduce((acc, item) => {
        // Check if the item already exists in the accumulator
        const foundIndex = acc.findIndex(
          (el) =>
            el?.id?.toString() === item?.id?.toString() &&
            el?.variant?.id?.toString() === item?.variant?.id?.toString()
        );

        if (foundIndex !== -1) {
          // If the item exists, update the quantity
          acc[foundIndex].quantity += item?.quantity;
        } else {
          // If the item does not exist, add it to the accumulator
          acc?.push(item);
        }

        return acc;
      }, []);

      LocalStorageService.set("items", newItems);
      state.items = newItems;
    },
    setCard: (state, action) => {
      const newItems = action.payload;
      LocalStorageService.set("items", newItems);
      state.items = newItems;
    },
  },
  extraReducers: (builder) => {},
});
export const { addCardItem, setCard } = cardSlice.actions;
export default cardSlice.reducer;
