import React from "react";
import "./CustomEmpty.scss";
import { theme, Empty } from "antd";
import EmptyImage from "../EmptyImage/EmptyImage";

export default function CustomEmpty({
  width,
  height,
  circleColor,
  boxBorderColor,
}) {
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <Empty
      image={EmptyImage(token, width, height, circleColor, boxBorderColor)}
    />
  );
}
