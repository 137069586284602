const LocalStorageService = {
    set: (key, value, config = {}) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    clear: () => {
      localStorage.clear();
    },
    remove: (key) => {
      localStorage.removeItem(key);
    },
    get: (key, config = {}) => {
      let str = localStorage.getItem(key);
      if (!str) {
        return null;
      }
      let item = JSON.parse(str);
      return item;
    },
  };
  
  export default LocalStorageService;
  