import { createSlice } from "@reduxjs/toolkit";
import { getShopDetails } from "./shopDetailsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  shop: null,
};

const shopDetails = createSlice({
  name: "shopDetails",
  initialState,
  reducers: {
    resetShopDetailsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShopDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShopDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.shop = action?.payload[0] ? action?.payload[0] : null;
      })
      .addCase(getShopDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});
export const { resetShopSettingError } = shopDetails.actions;
export default shopDetails.reducer;
