import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Products.scss";
import {
  theme,
  Typography,
  Input,
  Select,
  Button,
  Dropdown,
  Pagination,
  Spin,
  notification,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { CustomEmpty, ProductCard, ProductDrawer } from "../../components";
import {
  SearchOutlined,
  FunnelPlotOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { invertTheme } from "../../config/theme";
import { ProductFilters, PromoCodesSlider } from "./components";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaxPriceMenuItem,
  getMenuItems,
} from "../../features/menuItems/menuItemsAction";
import { getCategories } from "../../features/categories/categoriesActions";
import { getBrands } from "../../features/brands/brandsActions";
import classNames from "classnames";
import Utils from "../../utils";
import { resetMenuItemsError } from "../../features/menuItems/menuItemsSlice";
import { useSearchParams } from "react-router-dom";

const doSearch = (value, cb) => {
  cb({
    page: 1,
    per_page: 25,
    name: value ? value : undefined,
  });
};
const debouncedSearch = Utils?.debounce(doSearch, 500);

const rangeChange = (range, cb) => {
  cb({
    page: 1,
    per_page: 25,
    price_gte: range[0] >= 0 && range[0] <= range[1] ? range[0] : undefined,
    price_lte: range[1] >= 0 || range[1] >= range[0] ? range[1] : undefined,
  });
};
const debouncedPriceRangeChange = Utils?.debounce(rangeChange, 750);

export default function Products() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width, isMobile } = useWindowWidth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    loading,
    error,
    products: { meta, results: products },
  } = useSelector((state) => state?.menuItems);
  const {
    loading: catgeoriesLoading,
    categories: { results: categories },
  } = useSelector((state) => state?.categories);
  const {
    loading: brandsLoading,
    brands: { results: brands },
  } = useSelector((state) => state?.brands);

  const productContRef = useRef(null);
  const [is_initial_requests_done, set_is_initial_requests_done] =
    useState(false);
  const [searchVal, setSearchVal] = useState(searchParams?.get("name") || "");
  const [priceRange, setPriceRange] = useState([]);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const arrangeOptions = [
    {
      key: "ascending",
      label: "Ascending",
    },
    {
      key: "descending",
      label: "Descending",
    },
    {
      key: "reset",
      label: "Reset",
    },
  ];

  const setUrlSearchParams = (newParams) => {
    const filteredSearchParams = Utils?.get_filtered_url_params(searchParams, {
      page: searchParams?.get("page") ? searchParams?.get("page") : undefined,
      per_page: searchParams?.get("per_page")
        ? searchParams?.get("per_page")
        : undefined,
      order_by: searchParams?.get("order_by")
        ? searchParams?.get("order_by")
        : undefined,
      name: searchParams?.get("name") ? searchParams?.get("name") : undefined,
      category: searchParams?.get("category")
        ? searchParams.get("category")
        : undefined,
      brand_ids: searchParams?.get("brand_ids")?.length
        ? searchParams?.get("brand_ids")?.toString()
        : undefined,
      genetics: searchParams?.get("genetics")
        ? searchParams?.get("genetics")
        : undefined,
      price_gte:
        searchParams?.get("price_gte") >= 0 &&
        searchParams?.get("price_lte") >= searchParams?.get("price_gte")
          ? searchParams?.get("price_gte")
          : undefined,
      price_lte:
        searchParams?.get("price_lte") >= 0 &&
        searchParams?.get("price_lte") >= searchParams?.get("price_gte")
          ? searchParams?.get("price_lte")
          : undefined,
      sale: searchParams?.get("sale")
        ? Boolean(searchParams?.get("sale"))
        : undefined,
      ...newParams,
    });
    setSearchParams(filteredSearchParams);
  };

  const onSearchInpChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    debouncedSearch(value, () => {
      setUrlSearchParams({
        page: 1,
        per_page: 25,
        name: value ? value : undefined,
      });
    });
  };

  const onOrderChange = (val) => {
    if (val === "ascending") {
      setUrlSearchParams({
        page: 1,
        per_page: 25,
        order_by: "price",
      });
    } else if (val === "descending") {
      setUrlSearchParams({
        page: 1,
        per_page: 25,
        order_by: "-price",
      });
    }
  };

  const onOrderClear = () => {
    setUrlSearchParams({
      page: 1,
      per_page: 25,
      order_by: undefined,
    });
  };

  useEffect(() => {
    dispatch(
      getMaxPriceMenuItem({
        order_by: "-price",
        count_gt: 0,
      })
    )?.then((res) => {
      if (res?.type === "get_maxPrice_menu_item/fulfilled") {
        const dataByDescOrder = res?.payload;
        const items = dataByDescOrder?.results;
        const maxPrice = Utils?.getMaxPrice(items);
        setMaxPrice(maxPrice ? maxPrice : 1000);
        set_is_initial_requests_done(true);
      }
    });
    dispatch(
      getCategories({ page: 1, per_page: 100000, parent__isnull: true })
    );
    dispatch(getBrands({ page: 1, per_page: 100000 }));
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (is_initial_requests_done) {
      dispatch(
        getMenuItems({
          page: searchParams?.get("page") || 1,
          per_page: searchParams?.get("per_page") || 25,
          count_gt: 0,
          order_by: searchParams?.get("order_by") || undefined,
          name: searchParams?.get("name")?.toLocaleLowerCase() || undefined,
          category: searchParams?.get("category")?.split(",")?.length
            ? searchParams?.get("category")?.split(",")
            : undefined,
          brand_ids: searchParams?.get("brand_ids")?.toString() || undefined,
          genetics: searchParams?.get("genetics")?.split(",")?.length
            ? searchParams?.get("genetics")?.split(",")
            : undefined,
          price_gte: Number(searchParams?.get("price_gte")) || 0,
          price_lte: Number(searchParams?.get("price_lte")) || maxPrice || 1000,
          sale: Boolean(searchParams?.get("sale")) || undefined,
        })
      );
      setPriceRange([
        Number(searchParams?.get("price_gte")) || 0,
        Number(searchParams?.get("price_lte")) || maxPrice || 1000,
      ]);
    }
    setSearchVal(searchParams?.get("name") || "");
  }, [
    dispatch,
    isAuthenticated,
    searchParams,
    is_initial_requests_done,
    maxPrice,
  ]);

  const handlePagination = (e, a) => {
    setUrlSearchParams({
      page: e,
      per_page: a,
    });
  };

  const onCategoryChange = (_, categories) => {
    const getCategoryDetails = (arr) => {
      let res = {
        names: [],
        ids: [],
      };
      arr?.forEach((category) => {
        res?.names?.push(category?.label);
        res?.ids?.push(category?.value);
      });
      return res;
    };
    const categoryDetails = getCategoryDetails(categories);
    setUrlSearchParams({
      page: 1,
      per_page: 25,
      category: categoryDetails?.names?.length
        ? categoryDetails?.names?.toString()
        : undefined,
      brand_ids: undefined,
    });
    dispatch(
      getBrands({
        page: 1,
        per_page: 100000,
        categories: categoryDetails?.ids?.length
          ? categoryDetails?.ids?.toString()
          : undefined,
      })
    );
  };

  const onBrandChange = (brands) => {
    setUrlSearchParams({
      page: 1,
      per_page: 25,
      brand_ids: brands?.length ? brands?.toString() : undefined,
    });
  };

  const onGeneticsChange = (genetics) => {
    setUrlSearchParams({
      page: 1,
      per_page: 25,
      genetics: genetics?.length ? genetics?.toString() : undefined,
    });
  };

  const onCheckboxChange = (e) => {
    setUrlSearchParams({
      page: 1,
      per_page: 25,
      sale: e.target.checked ? e.target.checked : undefined,
    });
  };

  const onPriceRangeChange = (range) => {
    setPriceRange(range);
    debouncedPriceRangeChange(range, () => {
      setUrlSearchParams({
        page: 1,
        per_page: 25,
        price_gte: range[0] >= 0 && range[0] <= range[1] ? range[0] : undefined,
        price_lte: range[1] >= 0 || range[1] >= range[0] ? range[1] : undefined,
      });
    });
  };

  const openDrawer = (product) => {
    setIsDrawerOpened(true);
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (location?.pathname === "/") {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetMenuItemsError());
      }
    }
  }, [error, loading, location?.pathname, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content
        className="products-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <PromoCodesSlider isAuthenticated={isAuthenticated} token={token} />
        <div className="products-cont__globalFilters">
          {isMobile ? (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <div className="products-cont__globalFilters__productsCountWrapper__countCont">
                <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    All Products
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}>
                    {meta?.count ? meta?.count : 0}
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    results
                  </Typography.Text>
                </div>
                <Dropdown
                  className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown"
                  trigger={["click"]}
                  disabled={loading}
                  menu={{
                    items: arrangeOptions,
                    onClick: ({ key }) => {
                      if (key === "ascending") {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: "price",
                        });
                      } else if (key === "descending") {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: "-price",
                        });
                      } else {
                        setUrlSearchParams({
                          page: 1,
                          per_page: 25,
                          order_by: undefined,
                        });
                      }
                    },
                  }}>
                  <div className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content">
                    <Typography.Text
                      className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content__text"
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      {searchParams.get("order_by") === "price"
                        ? "Ascending"
                        : searchParams.get("order_by") === "-price"
                        ? "Descending"
                        : "Sort By"}
                    </Typography.Text>
                    <DownOutlined
                      style={{
                        fontSize: "14px",
                        color: token?.icons?.whiteIcon,
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
              <Button
                type={isFilterOpened ? "primary" : ""}
                icon={
                  <FunnelPlotOutlined
                    style={{
                      color: isFilterOpened
                        ? token?.icons?.bgColorIcon
                        : token?.icons?.whiteIcon,
                      fontSize: "16px",
                    }}
                  />
                }
                size="small"
                onClick={() => {
                  setIsFilterOpened(!isFilterOpened);
                }}
              />
            </div>
          ) : (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <Typography.Text
                className="products-cont__globalFilters__productsCountWrapper__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All Products
              </Typography.Text>
              <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  {meta?.count ? meta?.count : 0}
                </Typography.Text>
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  results
                </Typography.Text>
              </div>
            </div>
          )}
          <div className="products-cont__globalFilters__filtersWrapper">
            {isMobile && !isFilterOpened ? null : (
              <Input
                className="products-cont__globalFilters__filtersWrapper__searchInp"
                size="large"
                placeholder="Brands, products, retailers and more"
                prefix={<SearchOutlined />}
                allowClear
                onChange={onSearchInpChange}
                value={searchVal}
              />
            )}
            {isMobile ? null : (
              <div className="products-cont__globalFilters__filtersWrapper__selectWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  Sort By :
                </Typography.Text>
                <Select
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__select"
                  placeholder="Sort by"
                  size="large"
                  options={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  allowClear
                  onChange={onOrderChange}
                  disabled={loading}
                  onClear={onOrderClear}
                  value={
                    searchParams?.get("order_by") === "price"
                      ? "ascending"
                      : searchParams?.get("order_by") === "-price"
                      ? "descending"
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="products-cont__products">
          <ProductFilters
            token={token}
            invertTheme={invertTheme}
            isMobile={isMobile}
            isFilterOpened={isFilterOpened}
            loading={loading}
            categories={categories}
            catgeoriesLoading={catgeoriesLoading}
            selectedCategories={searchParams?.get("category")?.split(",") || []}
            onCategoryChange={onCategoryChange}
            filterOption={Utils?.filterOption}
            brands={brands}
            brandsLoading={brandsLoading}
            selectedBrands={searchParams?.get("brand_ids")?.split(",") || []}
            onBrandChange={onBrandChange}
            selectedGenetics={searchParams?.get("genetics")?.split(",") || []}
            onGeneticsChange={onGeneticsChange}
            priceRange={priceRange}
            onPriceRangeChange={onPriceRangeChange}
            isOnsale={Boolean(searchParams?.get("sale")) || false}
            onCheckboxChange={onCheckboxChange}
            maxPrice={maxPrice}
          />
          {isMobile ? (
            <div className="products-cont__products__titleWrapper">
              <Typography.Text
                className="products-cont__products__titleWrapper__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All The Products
              </Typography.Text>
            </div>
          ) : null}
          <div
            ref={productContRef}
            style={{
              gridTemplateColumns:
                !loading && products?.length
                  ? `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    }, ${
                      isMobile
                        ? 350
                        : 250 / productContRef?.current?.offsetWidth
                    }fr))`
                  : `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    },1fr))`,
            }}
            className={classNames("products-cont__products__cartsCont", {
              "products-cont__spinWrapper": loading || !products?.length,
            })}>
            {!products?.length && !loading ? (
              <CustomEmpty
                circleColor={token?.colorText ? token?.colorText : ""}
                boxBorderColor={token?.colorText ? token?.colorText : ""}
              />
            ) : !loading ? (
              products?.map((product) => (
                <ProductCard
                  product={product}
                  key={product?.id}
                  navigate={() => {
                    navigate(`product/${product?.id}`, { state: product });
                  }}
                  haveDrawer={true}
                  openDrawer={openDrawer}
                />
              ))
            ) : (
              <Spin size="large" />
            )}
          </div>
        </div>
        <Pagination
          className="products-cont__pagination"
          disabled={loading}
          pageSize={searchParams?.get("per_page") || 25}
          current={searchParams?.get("page") || 1}
          defaultCurrent={searchParams?.get("page") || 1}
          total={meta?.count ? meta?.count : ""}
          responsive={true}
          onChange={handlePagination}
          showQuickJumper={width < 584 && width >= 577 ? false : true}
          showSizeChanger={false}
        />
        <ProductDrawer
          isDrawerOpened={isDrawerOpened}
          close={() => {
            setIsDrawerOpened(false);
            setSelectedProduct(null);
          }}
          product={selectedProduct}
        />
      </Content>
    </>
  );
}
