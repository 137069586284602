import React from "react";
import "./Captcha.scss";
import { Image, Button, Spin } from "antd";
import { useDispatch } from "react-redux";
import { getCaptcha } from "../../features/captcha/captchaActions";
import { ReactComponent as RegenerateCaptchaIcon } from "../../assets/RegenerateCaptcha.svg";

export default function Captcha({
  captcha,
  isLoading,
  setCaptcha,
  setIsLoading,
}) {
  const dispatch = useDispatch();
  
  const reGenerateCaptcha = () => {
    setIsLoading(true);
    dispatch(getCaptcha())?.then((res) => {
      if (res?.type === "get_captcha/fulfilled") {
        setCaptcha(res?.payload);
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="captcha-cont">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Image
          alt="captcha"
          src={`data:image/png;base64,${captcha?.captcha_image}`}
        />
      )}
      <Button
        icon={<RegenerateCaptchaIcon />}
        type="text"
        onClick={reGenerateCaptcha}
      />
    </div>
  );
}
