import React, { useEffect } from "react";
import "./PromoCodesSlider.scss";
import { Typography } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  // Autoplay
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { PromoCard } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodes } from "../../../../features/promoCodes/promoCodeActions";

export default function PromoCodesSlider({ isAuthenticated, token }) {
  const {
    loading,
    codes: { results: promoCodes },
  } = useSelector((state) => state?.promoCodes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPromoCodes({ page: 1, per_page: 100, status: "active" }));
  }, [dispatch, isAuthenticated]);

  return (
    <div className="promoCodesSlider-cont">
      <Typography.Text
        className="promoCodesSlider-cont__title"
        style={{
          color: token?.Typography?.mainColorText,
        }}>
        Deals
      </Typography.Text>
      {promoCodes?.length && !loading ? (
        <div className="promoCodesSlider-cont__sliderCont">
          <Swiper
            className="mySwiper"
            style={{
              width: "100%",
            }}
            modules={[
              Navigation,
              //  Autoplay
            ]}
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              374: {
                slidesPerView: 1.8,
              },
              400: {
                slidesPerView: 2,
              },
              500: {
                slidesPerView: 2.5,
              },
              600: {
                slidesPerView: 3,
              },
              700: {
                slidesPerView: 3.3,
              },
              800: {
                slidesPerView: 3.7,
              },
              900: {
                slidesPerView: 4,
              },
              1100: {
                slidesPerView: 4.5,
              },
              1300: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 6,
              },
              1800: {
                slidesPerView: 7,
              },
              2500: {
                slidesPerView: 10,
              },
            }}
            spaceBetween={16}
            pagination={{
              type: "fraction",
            }}
            navigation={true} //this make display none to arrow icons

            // loop={true} // Enable infinite loop
            // autoplay={{
            //   delay: 2000, // Time between slides in milliseconds
            //   disableOnInteraction: false, // Keeps autoplay active even after user interaction
            // }}
          >
            {promoCodes?.map((promoCode, index) => (
              <SwiperSlide
                key={index}
                style={{
                  height: "auto",
                  minHeight: "auto",
                }}>
                <PromoCard key={promoCode?.id} promoCode={promoCode} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
    </div>
  );
}
